<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">Users</h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>
        <v-row>
            <v-col md="6">
                <v-form @submit.prevent="submit" ref="form" v-model="isValid">
                    <v-card
                        elevation="1"
                    >
                        <v-card-title class="primary-title">
                            Create new user
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-text-field
                                v-model="user.email"
                                :rules="emailRules"
                                label="E-mail address"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.firstName"
                                :rules="firstNameRules"
                                label="First name"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.lastName"
                                :rules="lastNameRules"
                                label="Last name"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.company"
                                :rules="companyRules"
                                label="Company name"
                                outlined
                                required
                            ></v-text-field>
                            <CountrySelect
                                v-model="user.country"
                                :rules="countryRules"
                                label="Country"
                                outlined
                                required
                            ></CountrySelect>
                            <v-text-field
                                v-model="user.businessPartnerNo"
                                :rules="businessPartnerNoRules"
                                label="Business partner number"
                                outlined
                                required
                            ></v-text-field>
                            <v-checkbox
                                v-if="canUserViewPrices"
                                v-model="user.showPrices"
                                label="Show prices">
                            </v-checkbox>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :disabled="!isValid || isSubmitting"
                                :loading="isSubmitting"
                                color="success"
                                @click="submit"
                                large
                            >
                                <v-icon
                                    small
                                    left
                                >
                                    fal fa-check
                                </v-icon>
                                Submit
                            </v-btn>
                            <v-btn
                                dense
                                plain
                                :to="{name: 'UserOverview'}"
                                class="ml-3"
                            >
                                Back
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CountrySelect from "../components/CountrySelect";
import {mapGetters} from "vuex";
export default {
    name: "UserCreate",
    components: {CountrySelect},
    mounted () {
        this.$store.dispatch('setPageTitle', 'Create user')
        this.preloadDataFromUser();
    },
    data: () => ({
        selectedProduct: null,
        isValid: false,
        isSubmitting: false,
        user: {
            company: '',
            country: '',
            businessPartnerNo: '',
            firstName: '',
            lastName: '',
            email: '',
            showPrices: false
        },
        companyRules: [
            v => !!v || 'Company name is required',
        ],
        firstNameRules: [
            v => !!v || 'First name is required',
        ],
        lastNameRules: [
            v => !!v || 'Last name is required',
        ],
        countryRules: [
            v => !!v || 'Country is required',
        ],
        businessPartnerNoRules: [
            v => !!v || 'Business Partner number is required',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
    }),
    computed: {
        breadcrumbs () {
            return  [{
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            }, {
                text: 'Users',
                disabled: false,
                to: {name: 'UserOverview'},
                exact: true
            }];
        },
        ...mapGetters([
            'canUserViewPrices',
        ])
    },
    methods: {
        preloadDataFromUser() {
            this.user.businessPartnerNo = this.$store.state.auth.user.businessPartnerNo;
            this.user.country = this.$store.state.auth.user.country+'';
            console.log(this.user.country);
        },
        submit() {
            if (this.$refs.form.validate() && !this.isSubmitting) {
                this.isSubmitting = true;
                this.$store.dispatch('createUser', { user: this.user })
                    .then(() => {
                        this.$store.dispatch('showSnackbar', { text: 'Invitation sent to '+this.user.email})
                        this.$router.push({ name: 'UserOverview'});
                    })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {
                        this.$store.dispatch('showSnackbar', { text: 'The given username is already in use', color: 'error'})
                    }else {
                        this.$store.dispatch('showSnackbar', { text: 'Could not create user', color: 'error'})
                    }

                    this.isSubmitting = false
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
