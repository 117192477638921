<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">Users</h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>
        <v-row v-if="!user">
            <v-col md="8">
                <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col md="8">
                <v-form @submit.prevent="submit" ref="form" v-model="isValid">
                    <v-card
                        elevation="1"
                    >
                        <v-card-title class="primary-title">
                            Edit {{user.username}}
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-text-field
                                v-model="user.username"
                                :rules="emailRules"
                                label="E-mail address"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.firstName"
                                :rules="firstNameRules"
                                label="First name"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.lastName"
                                :rules="lastNameRules"
                                label="Last name"
                                outlined
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.company"
                                :rules="companyRules"
                                label="Company name"
                                outlined
                                required
                            ></v-text-field>
                            <CountrySelect
                                v-model="user.country"
                                :rules="countryRules"
                                label="Country"
                                outlined
                                required
                            ></CountrySelect>
                            <v-text-field
                                v-model="user.businessPartnerNo"
                                :rules="businessPartnerNoRules"
                                label="Business partner number"
                                outlined
                                required
                            ></v-text-field>
                            <v-checkbox
                                v-if="canUserViewPrices"
                                v-model="user.showPrices"
                                label="Show prices">
                            </v-checkbox>
                            <v-checkbox
                                v-model="user.active"
                                label="User can log in">
                            </v-checkbox>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :disabled="!isValid || isSubmitting"
                                :loading="isSubmitting"
                                color="success"
                                @click="submit"
                                large
                            >
                                <v-icon
                                    small
                                    left
                                >
                                    fal fa-check
                                </v-icon>
                                Submit
                            </v-btn>
                            <v-btn
                                dense
                                plain
                                :to="{name: 'UserOverview'}"
                                class="ml-3"
                            >
                                Back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="isSubmitting"
                                :loading="isSubmitting"
                                color="error"
                                @click="showDeleteDialog = true"
                                large
                            >
                                <v-icon
                                    small
                                    left
                                >
                                    fal fa-trash-alt
                                </v-icon>
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-dialog
            v-model="showDeleteDialog"
            persistent
            max-width="300"
        >
            <v-card class="offwhite-card">
                <v-card-title class="primary-title justify-center">
                    <h1 class="text-h4">Delete</h1>
                </v-card-title>
                <v-card-text class="pt-5 text-center">Are you sure you want to delete this user?</v-card-text>
                <v-card-actions class="pa-4 pt-1">
                    <v-btn
                        text
                        color="secondary"
                        @click="showDeleteDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deleteUser">
                        <v-icon
                            small
                            left
                        >
                            fal fa-trash-alt
                        </v-icon>
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import CountrySelect from "../components/CountrySelect";
import {mapGetters} from "vuex";
export default {
    name: "UserEdit",
    components: {CountrySelect},
    mounted () {
        this.$store.dispatch('setPageTitle', 'Edit user')
        this.loadUserData();
    },
    data: () => ({
        selectedProduct: null,
        isValid: false,
        isSubmitting: false,
        showDeleteDialog: false,
        user: null,
        companyRules: [
            v => !!v || 'Company name is required',
        ],
        firstNameRules: [
            v => !!v || 'First name is required',
        ],
        lastNameRules: [
            v => !!v || 'Last name is required',
        ],
        countryRules: [
            v => !!v || 'Country is required',
        ],
        businessPartnerNoRules: [
            v => !!v || 'Business Partner number is required',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
    }),
    computed: {
        breadcrumbs () {
            return  [{
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            }, {
                text: 'Users',
                disabled: false,
                to: {name: 'UserOverview'},
                exact: true
            }];
        },
        ...mapGetters([
            'canUserViewPrices',
        ])
    },
    methods: {
        loadUserData() {
            this.$store.dispatch('getUserDetail', { id: parseInt(this.$route.params.userId) })
            .then(user => {
                if (!user) {
                    this.$store.dispatch('showSnackbar', { text: 'User not found', color: 'error'})
                    this.$router.push({ name: 'UserOverview'});
                }
                this.user = JSON.parse(JSON.stringify(user)); // deep copy, to lose Vuex binding
            })
        },
        submit() {
            if (this.$refs.form.validate() && !this.isSubmitting) {
                this.isSubmitting = true;
                this.$store.dispatch('updateUser', { user: this.user, id: parseInt(this.$route.params.userId) })
                    .then(() => {
                        this.$store.dispatch('showSnackbar', { text: 'User updated'})
                        this.$router.push({ name: 'UserOverview'});
                    })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {

                        this.$store.dispatch('showSnackbar', { text: 'The given username is already in use', color: 'error'})
                    }else {
                        this.$store.dispatch('showSnackbar', { text: 'Could not update user', color: 'error'})
                    }

                    this.isSubmitting = false
                })
            }
        },
        deleteUser() {
            if (!this.isSubmitting) {
                this.isSubmitting = true;
                this.$store.dispatch('deleteUser', { id: parseInt(this.$route.params.userId) })
                    .then(() => {
                        this.$store.dispatch('showSnackbar', { text: 'User deleted'})
                        this.$router.push({ name: 'UserOverview'});
                    })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {
                        this.$store.dispatch('showSnackbar', { text: 'Could not delete user', color: 'error'})
                    }

                    this.isSubmitting = false
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
