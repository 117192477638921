<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">
                Users
                <span class="text-caption">{{activeUserCount}} / {{maxUserCount}}</span>
            </h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>

        <v-alert color="warning" v-if="!isKeyUser">
            This functionality is only available for Key Users.
        </v-alert>

        <v-row v-if="isKeyUser && canAddUser">
            <v-col class="text-right">
                <v-btn
                    color="accent"
                    :to="{name:'UserCreate'}"
                >
                    <v-icon
                        left
                    >
                        fal fa-plus
                    </v-icon>
                    Add User
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="isKeyUser">
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="users"
                    :items-per-page="25"
                    :footer-props="{
                        itemsPerPageOptions:[10,25,50,100,-1]
                    }"
                    class="elevation-1"
                    :mobile-breakpoint="0"
                >
                    <template v-slot:item.username="{ item }">
                        <router-link :to="{name: 'UserEdit', params: {userId: item.id}}">
                            {{item.username}}
                        </router-link>
                    </template>
                    <template v-slot:item.active="{ item }">
                        <v-icon v-if="item.active" color="success">fal fa-check</v-icon>
                        <v-icon v-else color="error">fal fa-times</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserOverview",
    mounted () {
        this.$store.dispatch('setPageTitle', 'User')
        this.$store.dispatch('getUsers')
    },
    data: () => ({
        headers: [
            {text: 'E-mail', value: 'username'},
            {text: 'First name', value: 'firstName'},
            {text: 'Last name', value: 'lastName'},
            {text: 'Company', value: 'company'},
            {text: 'Active?', value: 'active' },
        ],
        breadcrumbs: [
            {
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            },
            {
                text: 'Users',
                disabled: false,
                to: { name: 'UserOverview' },
                exact: true
            }
        ]
    }),
    computed: {
        isKeyUser () {
            return this.$store.getters.isKeyUser
        },
        users() {
            return this.$store.state.users.users;
        },
        activeUserCount () {
            return this.$store.getters.activeUserCount;
        },
        maxUserCount () {
            return this.$store.state.auth.user.maxUsers;
        },
        canAddUser() {
            return this.activeUserCount < this.maxUserCount;
        }
    }
}
</script>

<style scoped>

</style>
